<template>
  <main>
    <Loading v-if="isLoading"></Loading>
    <div v-else>
      <h4 class="text-blue-900 text-lg font-semibold">
        Funds Transfer Provider Portal
      </h4>
      <p class="text-sm mt-10">Current Provider: {{ this.defaultProvider }}</p>
      <form @submit.prevent="saveConfig">
        <div class="grid grid-cols-2 gap-5 mt-10">
          <div class="flex flex-col pt-4">
            <label for="password" class="text-xs"
              >Funds Transfer Provider *</label
            >
            <select
              type="text"
              required
              v-model="terminal_provider_id"
              class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
            >
              <option :value="null">Select Terminal Provider</option>
              <option v-for="(provider, i) in providers" :key="i">
                {{ provider }}
              </option>
            </select>
          </div>
        </div>
        <button
          type="submit"
          class="block uppercase shadow bg-blue-600 mt-5 leading-6 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
        >
          Save
        </button>
      </form>
    </div>
  </main>
</template>

<script>
import { mapActions } from "vuex";
import Loading from "./general-components/Loading.vue";
export default {
  data() {
    return {
      isLoading: false,
      provider: [],
      providers: ["PAYSTACK", "ONEPIPE", "KUDA", "FLUTTERWAVE"],
      terminal_provider_id: null,
      defaultProvider: "",
      payload: {
        name: "DEFAULT",
        value: "",
        description: "Default fund transfer provider",
        type: "PROVIDER",
        enabled: true,
      },
    };
  },
  components: {
    Loading,
  },
  methods: {
    ...mapActions(["FETCH_TRANSFER_CONFIG"]),
    async saveConfig() {
      if (this.terminal_provider_id == "") {
        alert("Select a provider");
        return;
      }
      let res = confirm("Are you sure you want to do this?");
      if (res) {
        try {
          this.isLoading = true;
          this.payload.value = this.terminal_provider_id;
          let res = await this.$store.dispatch(
            "UPDATE_FUNDS_TRANSFER_CONFIG",
            this.payload
          );

          if (res.status) {
            this.isLoading = false;
            this.$router.go();
          } else {
            this.isLoading = false;
            alert(res.error);
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    async fetchConfig() {
      this.isLoading = true;
      try {
        let payload = {
          name: "DEFAULT",
        };
        let res = await this.$store.dispatch("FETCH_TRANSFER_CONFIG", payload);
        if (res.status) {
          this.defaultProvider = res.data.items[0].value;
        }
      } catch (error) {
        console.log(error);
      }

      this.isLoading = false;
    },
  },
  async created() {
    this.fetchConfig();
  },
};
</script>

<style></style>
